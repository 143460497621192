import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 210 63"
    {...props}
  >
    {children}
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M189.851 18.39a3.091 3.091 0 00-3.258 2.981l-2.073 19.758c-.888 8.5 4.072 11.481 9.254 11.481 5.626 0 9.7-3.8 10.364-10.512l2.146-20.727a2.49 2.49 0 00-2.665-2.981h-13.768zm-23.615 0a3.049 3.049 0 00-3.258 2.981L160.832 41.2c-1.258 12.6 7.994 18.341 15.768 18.414a16.514 16.514 0 007.847-1.789c1.332-.82 1.48-2.162.222-2.759-4.219-2.013-6.514-6.411-5.774-12.9l2.221-20.8a2.521 2.521 0 00-2.666-2.981h-12.214zm-23.762 0a3.091 3.091 0 00-3.258 2.981l-2.147 20.8c-1.332 13.42 7.181 18.042 16.213 17.82a17.932 17.932 0 006.44-1.343c1.332-.745 1.776-2.013.3-2.907-3.849-2.162-5.553-6.561-4.96-11.63l2.369-22.74a2.49 2.49 0 00-2.665-2.981h-12.292zm-39.087 41a19.458 19.458 0 008.957-1.714c1.7-.895 1.926-2.684.149-3.206-5.7-1.864-7.625-7.307-7.033-12.376l.888-8.574c1.48-13.719-16.434-13.793-17.618-1.715l-.963 8.8c-1.332 12.528 6.733 18.495 15.62 18.79m11.844-44.062a18.818 18.818 0 00-9.03 1.64c-1.7.895-1.926 2.684-.149 3.206 5.7 1.714 7.625 7.306 7.107 12.376l-.959 8.574c-1.479 13.718 16.435 13.793 17.618 1.714l.963-8.8c1.333-12.525-6.736-18.489-15.546-18.713M60.3 42.993c-1.407 13.2 7.625 17 15.841 16.625a20.257 20.257 0 007.921-1.64c1.48-.895 1.48-2.236.148-2.833-3.627-1.864-5.33-5.368-4.738-11.258l4.072-36.98A2.436 2.436 0 0080.884 4H67.336a3.079 3.079 0 00-3.257 2.907zm-29.977-6.561a2.522 2.522 0 002.665 2.982h16.137a3.048 3.048 0 003.258-2.982l.888-8.425a2.436 2.436 0 00-2.664-2.907H34.469a3.081 3.081 0 00-3.258 2.907zm2.221-21.1a2.491 2.491 0 002.665 2.982H53.79a3.048 3.048 0 003.257-2.982l.963-8.425A2.466 2.466 0 0055.344 4H36.689a3.08 3.08 0 00-3.258 2.907zM4.339 56.04A2.491 2.491 0 007 59.023h13.7a3.156 3.156 0 003.33-2.983l5.33-49.132A2.465 2.465 0 0026.7 4H12.853A3.036 3.036 0 009.6 6.908z"
      data-name="Angled Wordmark"
    />
  </svg>
))
